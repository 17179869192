.labels {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.label {
  flex: 1;

  font-size: 0.8em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sample {
  height: 5px;
  margin-top: 2px;
}
