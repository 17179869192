.header {
  z-index: 200;

  height: 80px;
  color: #f1f5f8;
  background-color: rgb(16, 42, 66);
  box-shadow: 0px 1px 10px 2px rgb(45, 84, 119);

  display: flex;
  flex-direction: row;
}

.title {
  flex: 1;

  line-height: 80px;
  font-size: 1.8em;
  margin-left: 10px;
  letter-spacing: 6px;
  font-family: Montserrat;
  font-weight: 300;
}

.title span {
  font-weight: 700;
}

.logo {
  height: 60px;
  width: 60px;
  margin-top: 10px;
  margin-left: 10px;
}
