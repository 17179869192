.labels {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 25px;
}

.label {
  flex: 1;

  font-size: 0.8em;
  justify-content: center !important;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: none;
}

.selected {
  font-weight: 700;
}
