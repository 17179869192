.container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.column {
  flex: 1;
  text-align: center;
  overflow-x: hidden;

  padding-left: 10px;
  padding-right: 10px;
}

.hint {
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
