body {
  margin: 0;
  font-family: 'Inter';
  background-color: #fbfdff;
  color: #324d67;
}

h1 {
  font-family: Montserrat;
  font-weight: 300;
  margin: 0;
}

code {
  font-family: 'Fira Mono';
  font-size: 0.85em;
}

[data-reach-dialog-overlay] {
  z-index: 200;
  background: rgba(0, 0, 0, 0.5);
}
[data-reach-popover] {
  z-index: 200;
}

.reflex-splitter {
  width: 11px !important;
  margin: 0 -5px;

  border-left: 5px solid rgba(50, 77, 103, 0) !important;
  border-right: 5px solid rgba(50, 77, 103, 0) !important;

  background-color: #324d67 !important;
  opacity: 0.3 !important;

  transition: border 200ms !important;

  box-sizing: border-box !important;
  background-clip: padding-box !important;
}

.reflex-splitter:hover {
  border-left: 5px solid rgba(50, 77, 103, 0.7) !important;
  border-right: 5px solid rgba(50, 77, 103, 0.7) !important;
}

.Pane {
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  background-color: #fafafa;
  width: 16px;
}

*::-webkit-scrollbar-track {
  background-color: #fafafa;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 16px;
  border: 4px solid #fafafa;
}

*::-webkit-scrollbar-button {
  display: none;
}
