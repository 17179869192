.palette {
  display: flex;
  flex-direction: row;
}

.swatches {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.swatch {
  flex: 1;
  position: relative;
  cursor: pointer;
}

.selected::after {
  content: ' ';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 10;
  border: 2px solid #eee;
  box-shadow: 1px 1px 2px 2px #333;
}

.newColorButton {
  display: block;
  margin-left: auto;
  margin-top: 20px;
}
