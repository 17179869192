.labels {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.label {
  width: 100px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;

  flex: 1;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: none;
  justify-content: center;
}

.selected {
  font-weight: 700;
}
