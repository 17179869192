.button {
  padding: 10px;

  transition: background 0.2s, border 0.2s;
  background: none;
  color: #324d67;
  border: 1px solid #cccccc;
  border-radius: 3px;
  cursor: pointer;

  font-family: 'Inter';
  font-size: 0.9em;

  display: inline-flex;
  align-items: center;

  max-width: 100%;
  overflow: hidden;
}

.button:focus {
  border-color: #2680c0;
  outline: none;
}

.button:hover {
  background-color: #ddd;
}

.button .text:nth-child(2) {
  margin-left: 5px;
}

.primary {
  background-color: #324d67;
  color: #f1f5f8;
}

.primary:hover {
  background-color: #213446;
}

.iconOnly {
  justify-content: center;
}

.link {
  border: none;
  background: none;

  padding: 0;
}

.link:hover {
  background: none !important;
  text-decoration: underline;
}

.toolbar {
  color: #f1f5f8;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 44px;
}

.toolbar:hover {
  background-color: #30465a;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
