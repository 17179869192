.dialog {
  max-width: 750px;
  overflow: hidden;

  color: #324d67;
  background-color: #f1f5f8;
}

.content {
  display: flex;
  overflow: hidden;
}

.recent {
  flex: 1;
  overflow: hidden;
}

.recentHeader {
  font-weight: 700;
}

.recentList {
  display: flex;
  flex-direction: column;
}

.recentList > * {
  margin-top: 5px;
}

.recentLastChanged {
  margin-left: 5px;
  text-decoration: none !important;
  color: #9daebd;
  font-size: 0.8em;
}

.logo {
  width: 300px;
  height: 310px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.toolbar {
  display: flex;
}

.toolbar button {
  margin-left: 10px;
}

.toolbarSpacer {
  flex: 1;
}

.hint {
  display: block;
  text-align: right;
  margin-bottom: 10px;
}
