.container pre::-webkit-scrollbar {
  background-color: rgb(71, 73, 73);
  width: 16px;
}

.container pre::-webkit-scrollbar-track {
  background-color: rgb(71, 73, 73);
}

.container pre::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 16px;
  border: 4px solid rgb(71, 73, 73);
}

.container pre::-webkit-scrollbar-button {
  display: none;
}
