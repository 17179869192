.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.tabbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.tabbar button {
  flex: 1;
  text-align: center;

  padding: 10px;
  padding: 10px;

  cursor: pointer;
  transition: all 0.2s;

  border: 1px solid transparent !important;
  border-radius: 0% !important;
  border-bottom: 2px solid transparent !important;

  background-color: #fbfdff;
}

.tabbar button:hover {
  background-color: #ccc;
}

.tabbar button:focus {
  border: 1px solid rgb(16, 42, 66) !important;
  outline: none;
}

.tabbar button[data-selected] {
  border-bottom: 2px solid rgb(16, 42, 66) !important;
}

.content {
  flex: 1;
  position: relative;
  overflow: auto;
}
