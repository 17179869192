.chart svg {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
}

.values {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.value {
  flex: 1;

  margin-left: 1px;
  margin-right: 1px;

  font-weight: 700;
  font-size: 0.8em;
}

.title {
  margin-bottom: 5px;
  font-size: 0.8em;
}
