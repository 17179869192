.input {
  font-family: 'Inter';
}

.input {
  width: calc(100% - 8px);
  border: 1px solid #ccc;
  background-color: #fefefe;
  color: #324d67;
  padding: 3px;
  height: 18px;
  transition: all 0.2s;
}

.input:hover {
  background-color: #f5f5f5;
}
.input:focus {
  border-color: #324d67;
  outline: none;
}
