.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;

  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}
