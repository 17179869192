.dialog {
  max-width: 750px;
  overflow: hidden;

  color: #324d67;
  background-color: #f1f5f8;
}

.content {
  display: flex;
  /* overflow: hidden; */
}

.recent {
  flex: 1;
  overflow: hidden;
}

.recentHeader {
  font-weight: 700;
}

.toolbar {
  display: flex;
}

.toolbar button {
  margin-left: 10px;
}

.toolbarSpacer {
  flex: 1;
}

.hint {
  display: block;
  text-align: right;
  margin-bottom: 10px;
}

.palette {
  list-style: none;
  width: 100%;
  padding: 0;
}

.paletteEntry {
  padding: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 2px solid transparent;
}

.paletteEntry:hover {
  z-index: 10;
  transform: scale(1.03);
  transform-origin: center;
  border: 2px solid #eee;
  box-shadow: 1px 1px 2px 2px #333;
}
