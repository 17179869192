.panel {
  margin: 10px;
}

.panelHeader {
  display: flex;
  flex-direction: row;
}

.panelTitle {
  flex: 1;

  line-height: 22px;
  font-size: 0.9em;
  font-weight: 700;
}

.expandButton {
  height: 22px;
  flex: 0 0 22px;
  line-height: 24px;
  padding: 0 !important;
  margin-right: 5px;
}

.expandButton svg {
  transition: all 0.2s;
}

.expanded {
  transform: rotate(90deg);
}

.content {
  padding-top: 10px;
}
