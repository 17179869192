.row {
  display: flex;
  flex-direction: row;
}

.label {
  flex: 0 0 50px;
  line-height: 28px;
  height: 20px;
  font-size: 0.7em;
  text-align: right;
  margin-right: 10px;
  font-family: 'Fira Mono';
}

.inputContainer {
  position: relative;
  min-width: 0;
  flex-basis: 100%;
  margin-bottom: 2px;
  height: 20px;
}
